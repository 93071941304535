import {
  fixFont,
  removeOpacity,
} from '../../migration/styles-transpiler.utils';

export function getNewCalendarPageStyles(oldCalendarPageProperties) {
  const newStyles = {
    param_font_pageTitleFont: fixFont(
      oldCalendarPageProperties.param_font_fontPageTitle,
      { size: 32 },
    ),
    param_color_pageTitleColor:
      oldCalendarPageProperties.param_color_colorPageTitle,
    param_font_sectionTitleFont: fixFont(
      oldCalendarPageProperties.param_font_fontPageTitle,
      { size: 20 },
    ),
    param_color_sectionTitleColor:
      oldCalendarPageProperties.param_color_colorPageTitle,
    param_font_primaryTextFont: fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 16 },
    ),
    'param_font_primaryTextFont▶︎m': fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 14 },
    ),
    param_color_primaryTextColor:
      oldCalendarPageProperties.param_color_colorBodyText,
    param_font_secondaryTextFont: fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 14 },
    ),
    param_color_secondaryTextColor:
      oldCalendarPageProperties.param_color_colorBodyText,
    param_font_dateTextFont: fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 16 },
    ),
    'param_font_dateTextFont▶︎m': fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 14 },
    ),
    param_color_dateTextColor:
      oldCalendarPageProperties.param_color_colorBodyText,
    param_font_timeSelectionTextFont: fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 16 },
    ),
    'param_font_timeSelectionTextFont▶︎m': fixFont(
      oldCalendarPageProperties.param_font_fontBodyText,
      { size: 14 },
    ),
    param_color_timeSelectionTextColor:
      oldCalendarPageProperties.param_color_colorBodyText,
    param_color_widgetBackgroundColor: removeOpacity(
      oldCalendarPageProperties.param_color_colorPageBackground,
    ),
    param_color_headerBackgroundColor:
      oldCalendarPageProperties.param_color_colorPageBackground,
    'alpha-param_color_headerBackgroundColor': '1',
    param_font_bookButtonTextFont: fixFont(
      oldCalendarPageProperties.param_font_fontButtonText,
      { size: 16 },
    ),
    'param_font_bookButtonTextFont▶︎m': fixFont(
      oldCalendarPageProperties.param_font_fontButtonText,
      { size: 14 },
    ),
    param_color_bookButtonTextColor:
      oldCalendarPageProperties.param_color_colorPrimaryButtonText,
    param_color_bookButtonHoverTextColor:
      oldCalendarPageProperties.param_color_colorPrimaryButtonText,
    param_color_bookButtonBGColor:
      oldCalendarPageProperties.param_color_colorButton,
    param_color_bookButtonHoverBGColor:
      oldCalendarPageProperties.param_color_colorButton,
    param_color_bookButtonBorderColor:
      oldCalendarPageProperties.param_color_colorButton,
    param_color_bookButtonHoverBorderColor:
      oldCalendarPageProperties.param_color_colorButton,
    param_color_actionColor: oldCalendarPageProperties.param_color_colorLink,
    param_color_dividerColor:
      oldCalendarPageProperties.param_color_colorSummaryBorders,
    'alpha-param_color_dividerColor':
      oldCalendarPageProperties['alpha-param_color_colorSummaryBorders'],
  };
  Object.keys(newStyles).forEach(
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    (key) => newStyles[key] === undefined && delete newStyles[key],
  );
  return newStyles;
}
