import {
  fixFont,
  removeOpacity,
} from '../../migration/styles-transpiler.utils';

export function getNewFormPageStyles(oldFormPageProperties) {
  const newStyles = {
    // Page Title
    param_font_sectionTitleFont: fixFont(
      oldFormPageProperties.param_font_fontPageTitle,
      { size: 20 },
    ),
    param_color_sectionTitleColor:
      oldFormPageProperties.param_color_colorPageTitle,
    // Text
    param_font_primaryTextFont: fixFont(
      oldFormPageProperties.param_font_fontBodyText,
      { size: 16 },
    ),
    param_color_primaryTextColor:
      oldFormPageProperties.param_color_colorBodyText,
    param_font_secondaryTextFont: fixFont(
      oldFormPageProperties.param_font_fontBodyText,
      { size: 14 },
    ),
    param_color_secondaryTextColor:
      oldFormPageProperties.param_color_colorBodyText,
    param_font_formFieldsLabelsFont: fixFont(
      oldFormPageProperties.param_font_fontBodyText,
      { size: 16 },
    ),
    param_color_formFieldsLabelsColor:
      oldFormPageProperties.param_color_colorBodyText,
    param_font_formFieldsInputTextFont: fixFont(
      oldFormPageProperties.param_font_fontBodyText,
      { size: 16 },
    ),
    // Field Text
    param_color_formFieldsInputTextColor:
      oldFormPageProperties.param_color_colorInputText,
    // Page background
    param_color_widgetBackgroundColor: removeOpacity(
      oldFormPageProperties.param_color_colorPageBackground,
    ),
    // Button Text
    param_font_mainButtonFont: fixFont(
      oldFormPageProperties.param_font_fontButtonText,
      { size: 16 },
    ),
    param_color_mainButtonTextColor:
      oldFormPageProperties.param_color_colorPrimaryButtonText,
    param_color_mainButtonHoverTextColor:
      oldFormPageProperties.param_color_colorPrimaryButtonText,
    // Button Background
    param_color_mainButtonBackgroundColorAndOpacity:
      oldFormPageProperties.param_color_colorButton,
    param_color_mainButtonBorderColorAndOpacity:
      oldFormPageProperties.param_color_colorButton,
    param_color_mainButtonHoverBackgroundColorAndOpacity:
      oldFormPageProperties.param_color_colorButton,
    param_color_mainButtonHoverColorAndOpacity:
      oldFormPageProperties.param_color_colorButton,
    // Field border
    param_color_formBorderColor:
      oldFormPageProperties.param_color_colorInputBorder,
    param_color_formFieldBackgroundColor:
      oldFormPageProperties.param_color_colorInputBackground,
    param_color_dividerColorAndOpacity:
      oldFormPageProperties.param_color_colorSummaryBorders,
    'alpha-param_color_dividerColorAndOpacity':
      oldFormPageProperties['alpha-param_color_colorSummaryBorders'],
  };
  Object.keys(newStyles).forEach(
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    (key) => newStyles[key] === undefined && delete newStyles[key],
  );
  return newStyles;
}
