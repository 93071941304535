export function fixFont(
  font: string,
  options: {
    size: number;
    htmlTag?: string;
    style?: { bold: boolean; italic: boolean; underline: boolean };
  },
): string {
  if (!font) {
    return font;
  }
  const newFont = {
    family: undefined,
    preset: 'Custom',
    size: options.size,
    htmlTag: options.htmlTag,
    style: options.style || { bold: false, italic: false, underline: false },
  };
  try {
    const parsedFont = JSON.parse(font);
    if (parsedFont.value) {
      newFont.family = parsedFont.value;
    } else {
      newFont.family = parsedFont.cssFontFamily.split(`'`)[1];
    }
    return JSON.stringify(newFont);
  } catch (err) {
    console.error('Error with fixing a specific font: ', err);
    return font;
  }
}

export function removeOpacity(rgbaColor?: string) {
  if (!rgbaColor) {
    return rgbaColor;
  }
  const splitColor = rgbaColor.split(',');
  if (splitColor?.[3]) {
    splitColor[3] = '1)';
    return splitColor.join(',');
  }
}
